import React from 'react'

import { MapPageContent } from '@/components/cms/__MapPageContent'
import Layout from '@/components/Layout'
import { CMSPageProps } from '@/interfaces/index'
import { fetchContent } from '@/utils/contentful/fetch-content'
import { ContentfulPagesQuery } from '@/utils/contentful/gql'

const CMSPage = ({ cmsData }: CMSPageProps) => {
  return (
    <Layout
      title={cmsData.pageTitle}
      url={cmsData.pageCanonicalUrl}
      description={cmsData.pageDescription}
      imageCTA={cmsData.metaImageCta}
      shouldBeIndexed={cmsData.shouldBeIndexed}
    >
      <MapPageContent pageContent={cmsData.pageContentCollection.items} />
    </Layout>
  )
}

export async function getStaticProps() {
  const data = await fetchContent(`{
    pagesCollection(where: { slug: "/" }, limit: 1) {
      items {
        ${ContentfulPagesQuery}
      }
    }
  }`)

  return {
    props: {
      cmsData: data.pagesCollection.items[0],
    },
  }
}

export default CMSPage
